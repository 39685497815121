.sidenav {
  height: 100%;
  background-color: #6bd425;
  overflow-x: hidden;
  /* margin-top: 56px; */
}

.main {
  padding: 8%;
  padding-top: 3.5%;
  height: 100%;
  background-color: #fafafa;
  overflow-x: auto;
  /* width: 1200px !important; */
  width: 100%;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}

@media screen and (max-width: 450px) {
  .login-form {
    margin-top: 10%;
  }
}

@media screen and (min-width: 450px) {
  .main {
    margin-left: 30%;
    position: fixed;
    top: 0;
  }

  .sidenav {
    width: 30%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .login-form {
    margin-top: 80%;
  }
}

.login-main-text {
  margin-top: 20%;
  padding: 80px;
  color: #fff;
}

.login-main-text h2 {
  font-weight: 300;
}

.btn-black {
  background-color: #000 !important;
  color: #fff;
}

.card.card-container {
  margin-top: 3%;
  background-color: #fafafa;
  border: none;
  padding-left: 0px;
  width: 25%;
  min-width: 200px;
  padding-right: 0px;
}
