$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #373a3c;
$gray-900: #212529;
$black: #000;

$blue: #127abf;
$indigo: #6610f2;
$purple: #613d7c;
$pink: #e83e8c;
$red: #e74a3b;
$orange: #ee7f01;
$yellow: #f6c23e;
$green: #67d525;
$teal: #36b9cc;
$cyan: cyan;
$lightblue: lighten($blue, 20%);
$coral: #ff7f50;

$primary: $green;
$secondary: $gray-800;
$success: $blue;
$info: $gray-600;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
// $light: $gray-900;
$dark: $gray-800;
$tertiary: $teal;

$theme-colors: (
  "white" : $white,
  "tertiary": $tertiary,
  "coral": $coral,
  "indigo": $indigo,
  "cyan" : $cyan
);

$brand-primary: #127abf;
$brand-secondary: #449414;

@import "~bootswatch/dist/cosmo/_variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootswatch/dist/cosmo/_bootswatch.scss";
@import "~formiojs/dist/formio.full.css";

.card {
  background-color: #f7f7f7;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0);
}

// targets pagination buttons of form
.formio-form > div > nav > ul.pagination .page-link {
  cursor: pointer;
  background-color: white;
  color: black;
}
.formio-form > div > nav > ul.pagination .page-item.active .page-link {
  color: black;
  background-color: gray;
  border-color: black;
}

body a[href*="help.form.io"] {
  display: none !important;
}

//targets next button
.btn.btn-primary.btn-wizard-nav-next{
  background-color: gray;
  border-color: black;
}


.btn-primary:not(:disabled):not(.disabled):active{
  background-color: gray !important;
  border-color: gray !important;
}

//targets previous button
.btn.btn-primary.btn-wizard-nav-previous{
  background-color: gray;
  border-color: black;
}
.btn.btn-secondary.btn-wizard-nav-cancel{
  background-color: gray;
  border-color: black;
}

.btn.btn-primary.btn-wizard-nav-submit{
  background-color: gray;
  border-color: black;
}

// targets the + / - buttons from the 'cost&finance' component
.btn.btn-primary.formio-button-add-row {
  color: white;
  background-color: #67d525;
  border-color: #67d525;
  border-radius: 5px;
  float: right;
}

.btn.btn-secondary.formio-button-remove-row {
  color: #fff;
  background-color: red;
  border-color: red;
  border-radius: 5px;
  float: right;
}

table.datagrid-table.table-bordered {
  border: 0px;
  width: 100%;
}


.formio-component-datagrid .datagrid-table > tbody > tr > td:last-child {
  width: 2%;
}

.formio-component-datagrid .datagrid-table, .formio-component-datagrid .datagrid-table td, .formio-component-datagrid .datagrid-table th {
  border: 0px !important;
  padding: 10px;
}

// .formio-component-datagrid .datagrid-table {
//   margin-left: -12px;
//   margin-right: 12px;
// }

// .formio-component-status {
//   width: 132px;
// }

.choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
  padding-right: 10px;
}

.fa-times-circle-o:before {
  content: "\f068";
}

.formio-form .pagination .page-link:focus{
  box-shadow: none;
}

.kf-input {
  //margin-right: 68px;
  padding-right: 81px; //89px;
  margin-left: 12px; //20px;
  width: 100%;
}

// hides 'submission complete' message
// .alert.alert-success {
//   display: none;
// }
