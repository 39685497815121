.ql-picker.ql-placeholder {
  width: 130px;
}

.ql-picker.ql-header {
  width: 75px !important;
}

.ql-picker.ql-font {
  width: 95px !important;
}

.ql-picker.ql-placeholder > span.ql-picker-label::before {
  content: "Formularfelder";
}

.ql-picker.ql-placeholder
  > span.ql-picker-options
  > span.ql-picker-item::before {
  content: attr(data-label);
}